/* Adiciona margem inferior nas linhas da tabela */
.table-row-gap {
    margin-bottom: 16px; /* Ajuste o valor para o espaçamento desejado */
}

.table-row-gap:last-child {
    margin-bottom: 0; /* Remove o espaço da última linha */
}

/* Corrige o display padrão do `tr` */
.ant-table-tbody > tr.table-row-gap {
    display: block; /* Necessário para que o margin funcione nas linhas */
}